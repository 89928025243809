* {
    margin: 0;
    padding: 0;
}

html,
body {
    width: 100%;
    height: 100%;
    
    /* inpiration for colors https://www.schemecolor.com/dark-night-sky.php */

    
    /* background: linear-gradient(to bottom,  #1c3138 0%,#37616F 100%);  */
    /* background: linear-gradient(to bottom,  #2e3438 0%,#37616F 100%);  */
    /* background: linear-gradient(to bottom,  #2F1b25 0%,#4b2b3a 100%);  */
    /* background: radial-gradient( #4b2b3a,#2F1b25,black);  */
    background: radial-gradient( #5e3649,#4b2b3a,#2F1b25); 
    
 
}

.webgl {
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

/* background: #0B1026;
background: -moz-linear-gradient(top,  #0B1026 0%, #102849 100%); 
background: -webkit-linear-gradient(top,  #0B1026 0%,#102849 100%); 
background: linear-gradient(to bottom,  #0B1026 0%,#102849 100%); /
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0B1026', endColorstr='#102849',GradientType=0 ); */